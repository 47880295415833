<template>
  <section>
    <!-- title text and switch button -->
    <!-- <div class="text-center">
      <h1 class="mt-5">ข้อมูลใบกำกับภาษี</h1>
      <p class="mb-2 pb-75">
        ลงทะเบียนและตรวจสอบรายละเอียดใบกำกับภาษีได้ที่นี่
      </p>
      <div class="d-flex align-items-center justify-content-center mb-5 pb-50">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          v-b-modal.modal-primary
        >
          ลงทะเบียนข้อมูล
        </b-button>
      </div>
    </div> -->

    <b-row class="pricing-card">
      <b-col class="mx-auto">
        <b-row>
          <b-col md="12">
            <b-card>
              <h1 >ข้อมูลใบกำกับภาษี</h1>
              <p >
                ลงทะเบียนและตรวจสอบรายละเอียดใบกำกับภาษีได้ที่นี่
              </p>
              <hr />
              <h4><b>ข้อมูลของคุณ</b></h4>
              <div align="center" v-if="selected == 'ใบกำกับภาษีอย่างย่อ'">
                <table class="center">
                  <tr>
                    <th id="idheard"><b>รูปแบบใบกำกับภาษี </b></th>
                    <th>{{ selected }}</th>
                  </tr>
                  </table>
              </div>
              <div align="center" v-else>
           
                <table class="center">
                  <tr>
                    <th id="idheard"><b>รูปแบบใบกำกับภาษี </b></th>
                    <th>{{ selected }}</th>
                  </tr>
                  <tr>
                    <th id="idheard"><b>ประเภทบุคคล </b></th>
                    <th>{{ person_type }}</th>
                  </tr>
                  <tr>
                    <th id="idheard"><b>ชื่อบริษัท </b></th>
                    <th>{{ company_name }}</th>
                  </tr>
                  <tr>
                    <th id="idheard"><b>ที่อยู่บริษัท </b></th>
                    <th>{{ address }}</th>
                  </tr>
                  <tr>
                    <th id="idheard"><b>ประเภทสถานประกอบการ </b></th>
                    <th>{{ typeTax }}</th>
                  </tr>
                  <tr v-if="typeTax === 'สาขา'">
                    <th id="idheard"><b>สาขา </b></th>
                    <th>{{ brach_id }}</th>
                  </tr>
                  <tr>
                    <th id="idheard"><b>ชื่อของคุณ </b></th>
                    <th>{{ name_your }}</th>
                  </tr>
                  <tr>
                    <th id="idheard"><b>ประเภทสถานประกอบการ </b></th>
                    <th>{{ typeTax }}</th>
                  </tr>
                  <tr>
                    <th id="idheard">
                      <b
                        ><b-img
                          style="max-width: 40px"
                          :src="require('@/assets/logo-etax.png')"
                        />
                        อีเมลสำหรับส่งเอกสารใบกำกับภาษีอิเล็กทรอนิกส์ </b
                      >
                    </th>
                    <th>{{ emailValue }}</th>
                  </tr>
                  <tr>
                    <th id="idheard"><b>หมายเลขโทรศัพท์ </b></th>
                    <th>{{ phone }}</th>
                  </tr>
                  <tr>
                    <th id="idheard"><b>เลขประจำตัวผู้เสียภาษี </b></th>
                    <th>{{ number_tax }}</th>
                  </tr>
                </table>
                <!-- <p><b>รูปแบบใบกำกับภาษี :</b> {{ selected }}</p>
              <p><b>ประเภทบุคคล :</b> {{ person_type }}</p>
              <p><b>ชื่อบริษัท :</b> {{ company_name }}</p>
              <p><b>ที่อยู่บริษัท :</b> {{ address }}</p>
              <p><b>ประเภทสถานประกอบการ :</b> {{ typeTax }}</p>
              <p v-if="typeTax === 'สาขา'"><b>สาขา :</b> {{ brach_id }}</p>
              <p><b>ชื่อของคุณ :</b>{{ name_your }}</p>
              
              <p>  <b-img style="max-width: 40px" :src="require('@/assets/logo-etax.png')"  />  <b>อีเมลสำหรับส่งเอกสารใบกำกับภาษีอิเล็กทรอนิกส์  
                :</b> {{ emailValue }}</p>
              <p><b>หมายเลขโทรศัพท์ :</b> {{ phone }}</p>
              <p><b>เลขประจำตัวผู้เสียภาษี :</b> {{ number_tax }}</p> -->
              </div>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-modal.modal-primary
              >
                แก้ไข
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal
      id="modal-primary"
      ok-only
      ok-title="ยกเลิก"
      ok-variant="danger"
      modal-class="modal-primary"
      centered
      title="เลือกรูปแบบใบกำกับภาษี"
      v-model="model_type"
    >
      <b-card-text>
        รูปแบบใบกำกับภาษี
        <input
          id="option-one-all3"
          v-model="selected"
          value="ใบกำกับภาษีอย่างย่อ"
          checked="checked"
          type="radio"
        />
        <label for="option-one-all3"> <span></span> ใบกำกับภาษีอย่างย่อ </label>
        <input
          id="option-one-all4"
          v-model="selected"
          value="ใบกำกับภาษีเต็มรูป"
          checked="checked"
          type="radio"
        />
        <label for="option-one-all4"> <span></span> ใบกำกับภาษีเต็มรูป </label>

        <b-card-text class="mt-1 mb-0" v-if="selected === 'ใบกำกับภาษีเต็มรูป'">
          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group label="ประเภทบุคคล">
                    <validation-provider
                      #default="{ errors }"
                      name="ประเภทบุคคล"
                      rules="required"
                    >
                      <b-form-select
                        v-model="person_type"
                        :options="options2"
                        :state="errors.length > 0 ? false : null"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="ชื่อบริษัท">
                    <validation-provider
                      #default="{ errors }"
                      name="ชื่อบริษัท"
                      rules="required"
                    >
                      <b-form-input
                        v-model="company_name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="ชื่อบริษัท"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="ที่อยู่บริษัท">
                    <validation-provider
                      #default="{ errors }"
                      name="ที่อยู่บริษัท"
                      rules="required"
                    >
                      <b-form-input
                        v-model="address"
                        :state="errors.length > 0 ? false : null"
                        placeholder="ที่อยู่บริษัท"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="10">
                  <b-form-group>
                    รูปแบบใบกำกับภาษี
                    <!-- <b-form-radio-group
                      v-model="typeTax"
                      :options="optionsTax"
                      value-field="item"
                      text-field="name"
                      disabled-field="notEnabled"
                      class="demo-inline-spacing"
                    /> -->
                    <input
                      id="option-one-all"
                      v-model="typeTax"
                      value="สำนักงานใหญ่"
                      checked="checked"
                      type="radio"
                    />
                    <label for="option-one-all">
                      <span></span> สำนักงานใหญ่
                    </label>
                    <input
                      id="option-one-all1"
                      v-model="typeTax"
                      value="สาขา"
                      checked="checked"
                      type="radio"
                    />
                    <label for="option-one-all1"> <span></span> สาขา </label>
                  </b-form-group>
                </b-col>
                <b-col cols="12" v-if="typeTax === 'สาขา'">
                  <b-form-group label="รหัสสาขา">
                    <b-form-input
                      id="d-email"
                      v-model="brach_id"
                      placeholder="รหัสสาขา"
                    /> </b-form-group
                ></b-col>

                <b-col md="12">
                  <b-form-group label="ชื่อของคุณ">
                    <validation-provider
                      #default="{ errors }"
                      name="ชื่อของคุณ"
                      rules="required"
                    >
                      <b-form-input
                        v-model="name_your"
                        :state="errors.length > 0 ? false : null"
                        placeholder="ชื่อของคุณ"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label=" ">
                    อีเมลสำหรับส่งเอกสารใบกำกับภาษีอิเล็กทรอนิกส์.
                    <b-img
                      style="max-width: 40px"
                      :src="require('@/assets/logo-etax.png')"
                    />
                    <validation-provider
                      #default="{ errors }"
                      name="อีเมล"
                      rules="required|email"
                    >
                      <b-form-input
                        v-model="emailValue"
                        :state="errors.length > 0 ? false : null"
                        type="email"
                        placeholder="อีเมล"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="หมายเลขโทรศัพท์">
                    <validation-provider
                      #default="{ errors }"
                      name="หมายเลขโทรศัพท์"
                      rules="required"
                    >
                      <b-form-input
                        type="number"
                        v-model="phone"
                        :state="errors.length > 0 ? false : null"
                        placeholder="หมายเลขโทรศัพท์"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="เลขประจำตัวผู้เสียภาษี">
                    <validation-provider
                      #default="{ errors }"
                      name="เลขประจำตัวผู้เสียภาษี"
                      rules="required"
                    >
                      <b-form-input
                        type="number"
                        v-model="number_tax"
                        :state="errors.length > 0 ? false : null"
                        placeholder="เลขประจำตัวผู้เสียภาษี"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- checkbox -->
                <b-col cols="12">
                  <b-form-group>
                    <validation-provider #default="{ errors }" rules="required">
                      <b-form-checkbox
                        id="checkbox-9"
                        name="checkbox-9"
                        v-model="Remember_me"
                        :state="errors.length > 0 ? false : null"
                      >
                        กรุณาตรวจสอบความถูกต้องของใบกำกับภาษี/ใบเสร็จรับเงินที่ท่านได้รับ
                        กรณีที่ต้องการแก้ไขใบกำกับภาษี/ใบเสร็จรับเงิน
                        สามารถดำเนินการได้ภายในเดือนของการซื้อขายและภายใน 7 วัน
                        นับจากวันที่ที่ปรากฏบนใบกำกับภาษี/ใบเสร็จรับเงิน
                        หากเกินเดือน หรือ เกิน 7 วัน บริษัท โซซิโอ จำกัด จะถือว่าเอกสารฉบับนี้ถูกต้องและสมบูรณ์
                      </b-form-checkbox>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
        <b-card-text class="mt-1 mb-0" v-else>
          <b-row>
            <b-col cols="12">
              <b-button variant="primary" type="submit" @click="save()">
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
  BModal,
  VBModal,
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
  BForm,
  BFormText,
  BFormDatalist,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import { required, email } from "@validations";
import api from "@/api";
/* eslint-disable global-require */
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    BModal,
    BFormRadioGroup,
    BFormGroup,
    BFormInput,
    BForm,
    BFormText,
    BFormDatalist,
    BFormSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      id: null,
      model_type: false,
      Remember_me: "",
      person_type: "",
      number_tax: "",
      phone: "",
      emailValue: "",
      name_your: "",
      brach_id: "",
      typeTax: "สำนักงานใหญ่",
      address: "",
      company_name: "",
      selected: "ใบกำกับภาษีอย่างย่อ",
      options: [
        { item: "A", name: "ใบกำกับภาษีอย่างย่อ" },
        { item: "B", name: "ใบกำกับภาษีเต็มรูป" },
      ],
      options2: [
        { value: "นิติบุคลไทย", text: "นิติบุคลไทย" },
        { value: "บุคคลธรรมดาไทย", text: "บุคคลธรรมดาไทย" },
        { value: "นิติบุคคลต่างด้าว", text: "นิติบุคคลต่างด้าว" },
        {
          value: "บุคคลธรรมดาต่างด้าว",
          text: "บุคคลธรรมดาต่างด้าว",
          disabled: true,
        },
      ],
      optionsTax: [
        { item: "com", name: "สำนักงานใหญ่" },
        { item: "brach", name: "สาขา" },
      ],
      emailValue: "",
      name: "",
      required,
      email,
    };
  },
  created() {
    if (localStorage.getItem("oneSelect") == 1) {
      localStorage.setItem("oneSelect", 2);
      window.location.reload();
    }
  },
  mounted() {
    this.loaddata();
  },

  methods: {
    loaddata() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      api
        .post("tax_invoice_get", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          if (response.data) {
            this.id = response.data.id;

            if (response.data.type !== "ใบกำกับภาษีอย่างย่อ") {
              // this.Remember_me = response.data.Remember_me;
              if (response.data.Remember_me === "true") {
                this.Remember_me === true;
              } else {
                this.Remember_me === false;
              }
              this.person_type = response.data.person_type;
              this.number_tax = response.data.number_tax;
              this.phone = response.data.phone;
              this.emailValue = response.data.emailValue;
              this.name_your = response.data.name_your;
              this.brach_id = response.data.brach_id;
              this.typeTax = response.data.typeTax;
              this.address = response.data.address;
              this.company_name = response.data.company_name;
              this.selected = response.data.type;
              this.model_type = false;
              console.log(" this.model_type :>> ", this.model_type);
            }
          }
        })
        .catch((error) => {});
    },
    save() {
      if (this.typeTax === "สำนักงานใหญ่") {
        this.brach_id = "-";
      }

      const params = {
        id: this.id,
        store_id: localStorage.getItem("store_id"),
        Remember_me: "true",
        person_type: "-",
        number_tax: "-",
        phone: "-",
        emailValue: "-",
        name_your: "-",
        brach_id: "-",
        typeTax: "-",
        address: "-",
        company_name: "-",
        type: this.selected,
      };
      console.log("params :>> ", params);
      if (!this.id) {
        var url = "tax_invoice_insert";
      } else {
        var url = "tax_invoice_update";
      }
      console.log("url :>> ", url);
      console.log("params :>> ", params);
      api
        .post(url, params)
        .then((response) => {
          console.log(" response.data :>> ", response.data);
          this.loaddata();
          this.$swal({
            title: "Good job!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          console.log("error.data :>> ", error.data);
          this.loaddata();
          this.$swal({
            title: "Error!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // alert("form submitted!");
          if (this.typeTax === "สำนักงานใหญ่") {
            this.brach_id = "-";
          }

          const params = {
            id: this.id,
            store_id: localStorage.getItem("store_id"),
            Remember_me: "true",
            person_type: this.person_type,
            number_tax: this.number_tax,
            phone: this.phone,
            emailValue: this.emailValue,
            name_your: this.name_your,
            brach_id: this.brach_id,
            typeTax: this.typeTax,
            address: this.address,
            company_name: this.company_name,
            type: this.selected,
          };
          console.log("params :>> ", params);
          if (!this.id) {
            var url = "tax_invoice_insert";
          } else {
            var url = "tax_invoice_update";
          }
          api
            .post(url, params)
            .then((response) => {
              console.log(" response.data :>> ", response.data);
              this.loaddata();
              this.$swal({
                title: "Good job!",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              console.log("error.data :>> ", error.data);
              this.loaddata();
              this.$swal({
                title: "Error!",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
#idheard {
  text-align: right;

 
}
b{
margin: 10px
}
@import "@core/scss/vue/pages/page-pricing.scss";
</style>
